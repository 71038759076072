import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SamhitaOndcWoCService } from '../../../shared/services/api/samhita-ondc-wo-c.service';
import { ToastService } from '../../../shared/services/other/toaster.service';
import { PasswordCriteria } from '../../../shared/types/types';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  passwordStrength: string = '';
  passwordStrengthPercentage: number = 0;
  passwordStrengthClass: string = '';
  setPasswordForm = this.fb.group({
    password: ['', [Validators.required,Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required,Validators.minLength(6)]],
    token: ['', [Validators.required]]
  });

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if(!params?.token){
        this.toastService.presentToast('Set password session expired', 'warning', 'bottom');
        this.router.navigate(['/login']);
        return
      }
      this.setPasswordForm.patchValue({ token: params.token});
    });
  }

  constructor(private fb: FormBuilder, private toastService: ToastService, private router: Router,private samhitaOndcWoCService: SamhitaOndcWoCService,private activatedRoute: ActivatedRoute) {}


  passwordCriteria: PasswordCriteria = {
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumbers: false,
    hasSpecialChars: false,
    minLength: 6,
    passMatch: false
  };

  checkPasswordStrength() {
    const password = this.setPasswordForm.get('password')?.value ?? '';
    this.passwordCriteria.hasUpperCase = /[A-Z]/.test(password);
    this.passwordCriteria.hasLowerCase = /[a-z]/.test(password);
    this.passwordCriteria.hasNumbers = /\d/.test(password);
    this.passwordCriteria.hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const totalCriteria = Object.values(this.passwordCriteria).filter(Boolean).length;

    if (password.length < 5 || totalCriteria < 2) {
      this.passwordStrength = 'Weak';
      this.passwordStrengthPercentage = 25;
      this.passwordStrengthClass = 'weak';
    } else if (password.length < 5 || totalCriteria < 3) {
      this.passwordStrength = 'Medium';
      this.passwordStrengthPercentage = 50;
      this.passwordStrengthClass = 'medium';
    } else if (password.length < 7 || totalCriteria < 4) {
      this.passwordStrength = 'Good';
      this.passwordStrengthPercentage = 75;
      this.passwordStrengthClass = 'good';
    } else {
      this.passwordStrength = 'Strong';
      this.passwordStrengthPercentage = 100;
      this.passwordStrengthClass = 'strong';
    }
    this.checkPasswordMatch()
  }

  onSubmit() {
    if (this.setPasswordForm.invalid) {
      this.toastService.presentToast('Please enter a valid password and confirm password', 'danger', 'bottom');
      return
    }

    if(this.passwordStrengthPercentage < 75){
      this.toastService.presentToast('Password strength is too low', 'danger', 'bottom');
      return
    }
    if(!this.passwordCriteria.passMatch){
      this.toastService.presentToast('Password and confirm password do not match', 'danger', 'bottom');
      return
    }
    // const payload : SetPasswordPayload = {
    //   new_password: this.setPasswordForm.get('password')?.value || '',
    //   token: this.setPasswordForm.get('token')?.value || ''
    // }
    // this.apiService.setPassword(payload).subscribe((res: any) => {
    //   if (res) {
    //     this.toastService.presentToast('Password set successfully', 'success',2000,'bottom');
    //     this.router.navigate(['/login']);
    //   } else {
    //     this.toastService.presentToast('Failed to set password', 'danger',2000,'bottom');
    //   }
    // })
  }

  checkPasswordMatch() {
    const password = this.setPasswordForm.get('password')?.value;
    const confirmPassword = this.setPasswordForm.get('confirmPassword')?.value;
    this.passwordCriteria.passMatch = password === confirmPassword;
  }
}
