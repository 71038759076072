import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate {

  constructor(private router: Router, private sharedService: SharedService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isAuthenticated() ? this.router.createUrlTree(['/']) : true;
  }

  private isAuthenticated(): boolean {
    return !!this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
  }
}
