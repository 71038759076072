import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EMAIL_PASSWORD_LOGIN_PAYLOAD, PHONE_PASSWORD_LOGIN_PAYLOAD, SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { ToastService } from 'src/app/shared/services/other/toaster.service';
import { LanguageService } from 'src/app/shared/services/other/language.service';
import { SamhitaOndcWoCService } from 'src/app/shared/services/api/samhita-ondc-wo-c.service';
import { AuthService } from 'src/app/shared/services/api/auth.service';

@Component({
  selector: 'app-email-otp',
  templateUrl: './email-otp.component.html',
  styleUrls: ['./email-otp.component.scss']
})
export class EmailOtpComponent implements OnInit {

  loginForm = this.formBuilder.group({
    username: ['', [Validators.required, Validators.minLength(6)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  loginType: string = 'mobile-otp' as 'mobile-otp' | 'email-otp';
  selectedLanguage = this.languageService.selectedLanguage;
  TEXT = this.languageService.TEXT;

  constructor(
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private samhitaOndcWoCService: SamhitaOndcWoCService,
    private authService: AuthService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    console.log(this.loginForm)
    this.clearSession();
  }

  clearSession() {
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
  }

  sendVerifyOtp() {
    const payload: {email?: string | null, password: string | null, phone?: string | null} = {
      password: this.loginForm.get('password')?.value || null,
      phone: null
    }
    if (!payload) return;

    const loginType = this.loginForm.get('username')?.value?.includes('@') ? 'email' : 'mobile';
    if (loginType == 'email') {
      payload.email = this.loginForm.get('username')?.value
      delete payload.phone;
    } else {
      payload.phone = this.loginForm.get('username')?.value || null;
      delete payload.email;
    }
    

    if (loginType == 'email') {
      this.emailPasswordLogin(payload);
    } else {
      this.phonePasswordLogin(payload);
    }
  }
  
  emailPasswordLogin(payload: EMAIL_PASSWORD_LOGIN_PAYLOAD) {
    this.samhitaOndcWoCService.emailPasswordLogin(payload).subscribe(
      (response: any) => {
        this.authService.createSession(response)
      }
    );
  }

  phonePasswordLogin(payload: PHONE_PASSWORD_LOGIN_PAYLOAD  ) {
    this.samhitaOndcWoCService.phonePasswordLogin(payload).subscribe(
      (response: any) => {
        this.authService.createSession(response)
      }
    );
  }

}
