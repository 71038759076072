<ion-app>
  <app-header *ngIf="showHeader"></app-header>

  <ion-content>
    <ion-split-pane
      contentId="main-content"
      [ngClass]="{ 'space-for-header': showHeader }"
    >
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>

  <app-footer *ngIf="showHeader"></app-footer>

  <app-overlay-loading></app-overlay-loading>
</ion-app>
