import { Injectable } from '@angular/core';
import { LoadingController, SpinnerTypes } from '@ionic/angular';
import { LoadingType } from '../../types/types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading: HTMLIonLoadingElement | null = null;
  loadingContent = new Subject<{message : string, spinner: LoadingType}>();

  constructor(private loadingController: LoadingController) {}

  async presentLoading(message: string = 'Please wait...', spinner: SpinnerTypes = 'lines-small') {
    if (this.loading) return;

    this.loading = await this.loadingController.create({
      message,
      spinner
    });
    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  showOverlayLoading(message: string = 'Loading...', spinner: LoadingType = LoadingType.LINES) {
    this.loadingContent.next({message, spinner})
    const loadingTrigger = document.getElementById('open-loading');
    if (loadingTrigger) {
      loadingTrigger.click();
    }
  }

  hideOverlayLoading() {
    const loadingElement = document.querySelector('ion-loading');
    if (loadingElement) {
      (loadingElement as HTMLIonLoadingElement).dismiss();
    }
  }
}
