import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { SharedService } from 'src/app/shared/services/other/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginType: 'otp' | 'username-password' = 'otp';

  constructor(
    private sharedService: SharedService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  getInSideApp(tokens: { access: string }, userId: string, loginType: string) {
    this.router.navigate([`auth/${tokens.access}`], { queryParams: { userId, loginType } });
  }

  clearSession() {
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
  }

  onloginTypeChange(event: any): void {
    this.loginType = event.detail.value;
  }

  movetoSignup() {
    this.router.navigate(['/signup']);
  }

}
