import { environment } from "src/environments/environment";

const SAMHITA_ONDC_BASE_URL = environment.API.SAMHITA_ONDC.BASE_URL;
const AZURE_SERVICE_BASE_URL = environment.API.AZURE_SERVICE.BASE_URL;
const AUS_BASE_URL = environment.API.AUS.BASE_URL;
const ENDPOINTS = {
    SAMHITA_ONDC: {
        SIGNUP: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.SIGNUP}`,
        OTP_LOGIN: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.OTP_LOGIN}`,
        REQUEST_OTP: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.REQUEST_OTP}`,
        EMAIL_PASSWORD_LOGIN: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.EMAIL_PASSWORD_LOGIN}`,
        PHONE_PASSWORD_LOGIN: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.PHONE_PASSWORD_LOGIN}`,
        GET_USER_DETAILS : `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.USER_DETAILS}`,
        WORK_EXPERIENCES: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.WORK_EXPERIENCES}`,
        GET_S3_SIGNED_URL: `${SAMHITA_ONDC_BASE_URL}${environment.API.SAMHITA_ONDC.END_POINT.V1.GET_S3_SIGNED_URL}`,
    },
    AUS: {
        GET_ADDRESS_BY_PINCODE: `${AUS_BASE_URL}${environment.API.AUS.END_POINT.GET_ADDRESS_BY_PINCODE}`,
    },
    AZURE_SERVICE: {
        TRANSLATE: `${AZURE_SERVICE_BASE_URL}${environment.API.AZURE_SERVICE.END_POINT.TRANSLATE}`,
        LANGUAGES: `${AZURE_SERVICE_BASE_URL}${environment.API.AZURE_SERVICE.END_POINT.LANGUAGES}`,
        SECRET_KEY: environment.API.AZURE_SERVICE.SECRET_KEY,
        REGION: environment.API.AZURE_SERVICE.REGION
    }
};

const URLS = {
    SAMHITA_APP: environment.URLS.SAMHITA_APP,
}

export { ENDPOINTS, URLS }
