import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordCriteria, SESSION_STORAGE_KEYS, SIGNUP_PAYLOAD } from 'src/app/shared/types/types';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { ToastService } from 'src/app/shared/services/other/toaster.service';
import { SamhitaOndcWoCService } from 'src/app/shared/services/api/samhita-ondc-wo-c.service';
;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm = this.fb.group({
    phone: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
  });

  passwordCriteria: PasswordCriteria = {
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumbers: false,
    hasSpecialChars: false,
    minLength: 6,
    passMatch: false
  };

  passwordStrength: string = '';
  passwordStrengthPercentage: number = 0;
  passwordStrengthClass: string = '';

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private sharedService: SharedService,
    private router: Router,
    private samhitaOndcWoCService: SamhitaOndcWoCService
  ) { }

  ngOnInit(): void {
    this.clearSession();
  }

  onSubmit() {
    if (this.signupForm.invalid) {
      this.toastService.presentToast('Please fill in all fields correctly.', 'warning');
      return;
    }

    if (this.passwordStrengthPercentage < 75) {
      this.toastService.presentToast('Password strength is too low', 'danger', 'bottom');
      return
    }
    if (!this.passwordCriteria.passMatch) {
      this.toastService.presentToast('Password and confirm password do not match', 'danger', 'bottom');
      return
    }
    const payload: SIGNUP_PAYLOAD = {
      is_active: true,
      is_superuser: false,
      email_verified: false,
      phone_verified: false,
      email: this.signupForm.get('email')?.value ?? '',
      phone: this.signupForm.get('phone')?.value ?? '',
      password: this.signupForm.get('password')?.value ?? '',
    }

    delete payload.confirmPassword
    this.samhitaOndcWoCService.signUp(payload).subscribe(
      (response: any) => {
        this.toastService.presentToast(response.message || 'User created successfully!', 'success');
        this.router.navigate(['/login']);
      }
    );
  }

  clearSession() {
    this.signupForm.reset();
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
  }

  checkPasswordStrength() {
    const password = this.signupForm.get('password')?.value ?? '';
    this.passwordCriteria.hasUpperCase = /[A-Z]/.test(password);
    this.passwordCriteria.hasLowerCase = /[a-z]/.test(password);
    this.passwordCriteria.hasNumbers = /\d/.test(password);
    this.passwordCriteria.hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const totalCriteria = Object.values(this.passwordCriteria).filter(Boolean).length;

    if (password.length < 5 || totalCriteria < 2) {
      this.passwordStrength = 'Weak';
      this.passwordStrengthPercentage = 25;
      this.passwordStrengthClass = 'weak';
    } else if (password.length < 5 || totalCriteria < 3) {
      this.passwordStrength = 'Medium';
      this.passwordStrengthPercentage = 50;
      this.passwordStrengthClass = 'medium';
    } else if (password.length < 7 || totalCriteria < 4) {
      this.passwordStrength = 'Good';
      this.passwordStrengthPercentage = 75;
      this.passwordStrengthClass = 'good';
    } else {
      this.passwordStrength = 'Strong';
      this.passwordStrengthPercentage = 100;
      this.passwordStrengthClass = 'strong';
    }
    this.checkPasswordMatch()
  }

  checkPasswordMatch() {
    const password = this.signupForm.get('password')?.value;
    const confirmPassword = this.signupForm.get('confirmPassword')?.value;
    this.passwordCriteria.passMatch = password === confirmPassword;
  }
}
