import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InterceptorService } from './core/interceptor/interceptor.service';
import { LoginComponent } from './core/components/login/login.component';
import { SignupComponent } from './core/components/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayLoadingComponent } from './shared/components/overlay-loading/overlay-loading.component';
import { PhoneNumberDirective } from './core/directives/phone-number.directive';
import { SetPasswordComponent } from './core/components/set-password/set-password.component';
import { MobileOtpComponent } from './core/components/login/mobile-otp/mobile-otp.component';
import { EmailOtpComponent } from './core/components/login/email-otp/email-otp.component';

@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent, AuthComponent, NotFoundComponent, LoginComponent, SignupComponent, OverlayLoadingComponent, PhoneNumberDirective, SetPasswordComponent, MobileOtpComponent, EmailOtpComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,ReactiveFormsModule,FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, provideHttpClient(withInterceptorsFromDi()), {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
