<form [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()">
  <div>
    <ion-input
      formControlName="phone"
      (ionInput)="onPhoneInput($event)"
      label="Phone Number / Email"
      label-placement="floating"
      fill="outline"
      placeholder="Enter Phone Number / Email"
    ></ion-input>
    <div
      *ngIf="
        otpForm.get('phone')?.invalid &&
        (otpForm.get('phone')?.touched || otpForm.get('phone')?.dirty)
      "
      class="error-head"
    >
      <small
        class="error-message"
        *ngIf="otpForm.get('phone')?.errors?.['required']"
        >Phone number / Email is required</small
      >
      <small
        class="error-message"
        *ngIf="otpForm.get('phone')?.errors?.['minlength']"
        >Invalid phone number / Email format</small
      >
    </div>
    <ion-input
      formControlName="code"
      *ngIf="otpSent"
      class="space-input"
      label="OTP"
      label-placement="floating"
      fill="outline"
      placeholder="Enter OTP"
    ></ion-input>
  </div>
  <div class="resend-otp-container" *ngIf="otpSent">
    <ion-button
      *ngIf="canResendOtp"
      class="resend-otp-button"
      [disabled]="!canResendOtp"
      size="small"
      color="primary"
      fill="outline"
      (click)="sendVerifyOtp('send')"
    >
      Send OTP
    </ion-button>
    <p *ngIf="countdown > 0" class="resend-otp-text">
      Resend OTP in {{ countdown }} seconds.
    </p>
  </div>
  <ion-button
    type="submit"
    color="primary"
    expand="full"
    class="otp-button"
    [disabled]="otpForm.invalid"
  >
      {{ otpSent ? "Verify OTP" : "Send OTP" }}
  </ion-button>
</form>
