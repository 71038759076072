<ion-content>
  <div class="set-password-container">
    <ion-card>
      <ion-card-header class="set-password-header"
      >
        <ion-card-title>Set Your Password</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
          <ion-item>
            <ion-label position="floating">Enter Password</ion-label>
            <ion-input
              type="password"
              formControlName="password"
              (ionInput)="checkPasswordStrength()"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Confirm Password</ion-label>
            <ion-input
              type="password"
              formControlName="confirmPassword"
              (ionInput)="checkPasswordMatch()"
            ></ion-input>
          </ion-item>
          <div *ngIf="passwordStrength" class="password-strength-container">
            <p>Password Strength: {{ passwordStrength }}</p>
            <ul>
              <li>
                <span
                  [ngClass]="{
                    checkmark: passwordCriteria.hasUpperCase,
                    wrongmark: !passwordCriteria.hasUpperCase
                  }"
                >
                  <ion-icon
                    *ngIf="passwordCriteria.hasUpperCase"
                    name="checkmark-circle"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="!passwordCriteria.hasUpperCase"
                    name="close-circle"
                  ></ion-icon>
                  Uppercase Letter
                </span>
              </li>
              <li>
                <span
                  [ngClass]="{
                    checkmark: passwordCriteria.hasLowerCase,
                    wrongmark: !passwordCriteria.hasLowerCase
                  }"
                >
                  <ion-icon
                    *ngIf="passwordCriteria.hasLowerCase"
                    name="checkmark-circle"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="!passwordCriteria.hasLowerCase"
                    name="close-circle"
                  ></ion-icon>
                  Lowercase Letter
                </span>
              </li>
              <li>
                <span
                  [ngClass]="{
                    checkmark: passwordCriteria.hasNumbers,
                    wrongmark: !passwordCriteria.hasNumbers
                  }"
                >
                  <ion-icon
                    *ngIf="passwordCriteria.hasNumbers"
                    name="checkmark-circle"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="!passwordCriteria.hasNumbers"
                    name="close-circle"
                  ></ion-icon>
                  Number
                </span>
              </li>
              <li>
                <span
                  [ngClass]="{
                    checkmark: passwordCriteria.hasSpecialChars,
                    wrongmark: !passwordCriteria.hasSpecialChars
                  }"
                >
                  <ion-icon
                    *ngIf="passwordCriteria.hasSpecialChars"
                    name="checkmark-circle"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="!passwordCriteria.hasSpecialChars"
                    name="close-circle"
                  ></ion-icon>
                  Special Character
                </span>
              </li>
              <li>
                <span
                  [ngClass]="{
                    checkmark: passwordCriteria.minLength,
                    wrongmark: !passwordCriteria.minLength
                  }"
                >
                  <ion-icon
                    *ngIf="passwordCriteria.minLength"
                    name="checkmark-circle"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="!passwordCriteria.minLength"
                    name="close-circle"
                  ></ion-icon>
                  Minimum Length
                </span>
              </li>
              <li>
                <span
                  [ngClass]="{
                    checkmark: passwordCriteria.passMatch,
                    wrongmark: !passwordCriteria.passMatch
                  }"
                >
                  <ion-icon
                    *ngIf="passwordCriteria.passMatch"
                    name="checkmark-circle"
                  ></ion-icon>
                  <ion-icon
                    *ngIf="!passwordCriteria.passMatch"
                    name="close-circle"
                  ></ion-icon>
                  Passwords Match
                </span>
              </li>
            </ul>
            <div class="strength-bar">
              <div
                [ngClass]="passwordStrengthClass"
                [style.width.%]="passwordStrengthPercentage"
              ></div>
            </div>
          </div>
          <ion-button
            type="submit"
            [disabled]="setPasswordForm.invalid || !passwordCriteria.passMatch || passwordStrengthPercentage < 75"
            color="primary"
            expand="block"
            >Submit</ion-button
          >
        </form>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
