import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) {}

  async presentToast(
    message: string, 
    color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' = 'primary', 
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    showCloseButton: boolean = false,
    closeButtonText: string = 'Close'
  ) {
    const toast = await this.toastController.create({
      message,
      duration: 2500,
      color,
      position,
      buttons: showCloseButton ? [
        {
          text: closeButtonText,
          role: 'cancel'
        }
      ] : []
    });

    toast.present();
  }
}
