import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/constants';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { SESSION_STORAGE_KEYS, USER_DETAILS } from 'src/app/shared/types/types';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isMobile = true;
  selectedRoute: string = '';
  userData: USER_DETAILS | null = null;
  menuItems: any[] = [];
  grantList: any[] = [];
  private subscriptions: Subscription = new Subscription();
  constructor(private sharedService: SharedService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedRoute = event.url;
      }
    });
    this.isMobile = this.sharedService.isMobile();
    this.loadRequiredData();
    const userSessionSubscription =
      this.sharedService.userSessionCreated.subscribe(() =>
        this.loadRequiredData()
      );
    this.subscriptions.add(userSessionSubscription);
  }

  private loadRequiredData(): void {
    this.userData =
      (this.sharedService.getDataFromSessionStorage(
        SESSION_STORAGE_KEYS.USER_INFO
      ) as USER_DETAILS) || null;
    this.grantList = this.sharedService.getGrantList();
    this.menuItems = AppConstants.MENU_ITEMS;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isGrantAllowed(permission: string[]) {
    return (
      permission.length === 0 ||
      permission.some((permission: string) =>
        this.grantList.includes(permission)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
