import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogData, SESSION_STORAGE_KEYS } from '../../types/types';
import { AlertController } from '@ionic/angular';
import { SamhitaOndcWoCService } from '../api/samhita-ondc-wo-c.service';
  
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  secretKey = environment.SECRET_KEY;
  secretIV = environment.SECRET_IV;
  userSessionCreated = new Subject();
  newGrantCreated = new Subject();


  constructor(private alertController: AlertController,private samhitaOndcWoCService:SamhitaOndcWoCService) { }

  setDataToSessionStorage({ key, data }: { key: SESSION_STORAGE_KEYS; data: any }) {
    const encryptedData = this.encryptData(data);
    localStorage.setItem(key, encryptedData);
  }

  removeDataToSessionStorage(key: SESSION_STORAGE_KEYS) {
    localStorage.removeItem(key);
  }

  getDataFromSessionStorage(key: SESSION_STORAGE_KEYS) {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      return this.decryptResponse(encryptedData);
    }
    return null;
  }

  encryptData(payload: any) {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const iv = CryptoJS.enc.Utf8.parse(this.secretIV);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(payload), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC
    }).toString();
    return encrypted;
  }

  decryptResponse(encryptedData: string): any {
    try {
      const key = CryptoJS.enc.Utf8.parse(this.secretKey);
      const iv = CryptoJS.enc.Utf8.parse(this.secretIV);
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC
      });

      const decryptedString = decryptedData.toString(CryptoJS.enc.Utf8);

      if (!decryptedString) {
        console.log('Decrypted string is empty or malformed');
        return null;
      }

      const result = JSON.parse(decryptedString);
      return result;
    } catch (error) {
      console.log('Decryption Error:', error);
      return null;
    }
  }


  getAgeFromDob(date_of_birth: string) {
    if (!date_of_birth) {
      return '';
    }
    const dob = new Date(date_of_birth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  clearSessionStorage() {
    localStorage.clear();
  }
  async showConfirmationDialog(data: ConfirmationDialogData, handler: any) {
    const alert = await this.alertController.create({
      header: data.header,
      message: data.message,
      buttons: [
        {
          text: data.cancelText || 'Cancel',
          role: 'cancel'
        },
        {
          text: data.confirmText || 'Delete',
          handler: () => handler()
        }
      ]
    });
    await alert.present();
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  async convertBinarytoS3(file: any) {
    try {
      let formData = new FormData();
      formData.append("file", file);
      const res: any = await this.samhitaOndcWoCService.getS3SignedUrl(formData)
      return res?.media_url;
    } catch (error) {
      console.error("Error converting binary to S3:", error);
      return null;
    }
  }

  getGrantList() {
    const grantList = this.getDataFromSessionStorage(SESSION_STORAGE_KEYS.USER_GRANTS)?.data || [];
    const grants: any[] = []
    grantList.forEach((grant: any) => {
      grant.menu_item.forEach((item: any) => {
        grants.push(item)
      })
    })
    return grants;
  }

  getUserRoleId() {
    const userRoleData = this.getDataFromSessionStorage(SESSION_STORAGE_KEYS.USER_ROLE)
    if (!userRoleData) return null
    return userRoleData
  }

}
