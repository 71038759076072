<form [formGroup]="loginForm" (ngSubmit)="sendVerifyOtp()">
  <ion-input
    class="space-input"
    type="email"
    formControlName="username"
    label="Phone number / Email"
    required
    label-placement="floating"
    fill="outline"
    placeholder="Enter Phone number / Email"
  ></ion-input>
  <div
    *ngIf="
      loginForm.get('username')?.invalid &&
      (loginForm.get('username')?.touched || loginForm.get('username')?.dirty)
    "
    class="error-head"
  >
    <small
      class="error-message"
      *ngIf="loginForm.get('username')?.errors?.['required']"
      >Phone Number / Email is required</small
    >
    <small
      class="error-message"
      *ngIf="loginForm.get('username')?.errors && !loginForm.get('username')?.errors?.['required']"
        >Invalid phone number / Email format</small
    >
  </div>

  <ion-input
    class="space-input"
    type="password"
    formControlName="password"
    required
    label-placement="floating"
    fill="outline"
    placeholder="Enter Password"
    label="Password"
  ></ion-input>
  <div
    *ngIf="
      loginForm.get('password')?.invalid &&
      (loginForm.get('password')?.touched || loginForm.get('password')?.dirty)
    "
    class="error-head"
  >
    <small
      class="error-message"
      *ngIf="loginForm.get('password')?.errors?.['required']"
      >Password is required</small
    >
    <small
      class="error-message"
      *ngIf="loginForm.get('password')?.errors?.['minlength']"
      >Password must be at least 6 characters long</small
    >
  </div>

  <ion-button
    expand="full"
    type="submit"
    class="login-button"
    [disabled]="loginForm.invalid"
    >Login</ion-button
  >
</form>
