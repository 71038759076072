import { TEXT_IN_ALL_LANGUAGES } from "src/app/language/language";


enum LoadingType {
  BUBBLES = 'bubbles',
  CIRCLES = 'circles',
  CIRCULAR = 'circular',
  CRESCENT = 'crescent',
  DOTS = 'dots',
  LINES = 'lines',
  LINES_SHARP = 'lines-sharp',
  LINES_SHARP_SMALL = 'lines-sharp-small',
  LINES_SMALL = 'lines-small'
}

enum SESSION_STORAGE_KEYS {
  SESSION_TOKEN = 'sessionToken',
  USER_INFO = 'userInfo',
  USER_ID = 'userId',
  USER_GRANTS = 'userGrants',
  CURRENT_LANGUAGE = 'currentLanguage',
  USER_ROLE = 'userRole'
}

interface ConfirmationDialogData {
  header: string;
  message: string;
  cancelText: string;
  confirmText: string;
}

interface LoadingMessage {
  endpoint: string;
  method: string;
  message: string;
}

interface PasswordCriteria {
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumbers: boolean;
  hasSpecialChars: boolean;
  minLength: number;
  passMatch: boolean;
}

interface BackButtonEvent {
  register: (id: number, callback: () => void) => void;
}

enum USER_PROFILE_SEGMENT {
  PERSONAL = 'personal',
  ADDRESS = 'address',
  BANK = 'bank',
  QUALIFICATIONS = 'qualifications',
  EXPERIENCE = 'experience'
}

interface OTP_LOGIN_PAYLOAD {
  phone?: string | null;
  email?: string | null;
  code?: string | null;
}

interface EMAIL_PASSWORD_LOGIN_PAYLOAD {
  email?: string | null;
  password: string | null;
}

interface PHONE_PASSWORD_LOGIN_PAYLOAD {
  phone?: string | null;
  password: string | null;
}

interface SIGNUP_PAYLOAD {
  phone: string;
  email: string;
  password: string;
  confirmPassword?: string;
  is_active: boolean;
  is_superuser: boolean;
  email_verified: boolean;
  phone_verified: boolean;
}

interface USER_DETAILS {
  created_at: string;
  email: string;
  email_verified: boolean;
  id: string;
  is_active: boolean;
  is_superuser: boolean;
  phone: string;
  phone_verified: boolean;
  profile: {
    name: string;
    gender: string;
    date_of_birth: string;
    id_numbers: {
      aadhaar: string;
      pan: string;
      voter_id: string;
      driving_license: string;
      medical_records: string[];
    };
    address: {
      street: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
      address_proof_url: string;
    };
    bank_details: {
      account_number: string;
      ifsc_code: string;
      bank_name: string;
      branch_name: string;
      account_holder_name: string;
    };
    highest_qualification: string;
    total_experience_years: number;
    certifications: string[];
    assessment_scores: {};
    reviews: string[];
    language_proficiencies: {
      language: string;
      proficiency: string;
    }[];
    desired_job_titles: string[];
    aspirational_job_titles: string[];
    job_types: string[];
    job_status: string;
    desired_locations: string[];
    willing_to_relocate: boolean;
    desired_salary_range: {
      minimum: number;
      maximum: number;
      currency: string;
    };
    aspirational_companies: string[];
    id: string;
    user_id: string;
    work_experience: any[];
    created_at: string;
    updated_at: string;
  };
  updated_at: string;
}

type LanguageKeys = keyof typeof TEXT_IN_ALL_LANGUAGES;
export { LoadingType, LoadingMessage, BackButtonEvent, SESSION_STORAGE_KEYS, PasswordCriteria, ConfirmationDialogData, LanguageKeys, USER_PROFILE_SEGMENT, OTP_LOGIN_PAYLOAD, EMAIL_PASSWORD_LOGIN_PAYLOAD, PHONE_PASSWORD_LOGIN_PAYLOAD, SIGNUP_PAYLOAD, USER_DETAILS }
