<ion-footer *ngIf="isMobile && userData?.email">
  <ion-tab-bar slot="bottom">
   <ng-container *ngFor="let item of menuItems">
    <ng-container *ngIf="isGrantAllowed(item.permission)">
      <ion-tab-button  [tab]="item.route" [selected]="item.route === selectedRoute" #tabButton [routerLink]="item.route">
        <ion-icon [name]="item.icon"></ion-icon>
        {{ item.label }}
      </ion-tab-button>
      </ng-container>
    </ng-container>
  </ion-tab-bar>
</ion-footer>