export const environment = {
  production: false,
  API: {
    SAMHITA_ONDC: {
      BASE_URL: 'https://samhita-wo-c-backend-dev.samhita.org/api',
      END_POINT: {
        V1: {
          SIGNUP: '/v1/auth/signup',
          PHONE_PASSWORD_LOGIN: '/v1/auth/login/phone',
          EMAIL_PASSWORD_LOGIN: '/v1/auth/login/email',
          OTP_LOGIN: '/v1/auth/login/otp',
          REQUEST_OTP: '/v1/auth/request-otp',
          USER_DETAILS: '/v1/users/me',
          WORK_EXPERIENCES: '/v1/profiles/me/work-experience',
          GET_S3_SIGNED_URL: '/v1/s3/get-signed-url'
        }
      },
    },
    AUS: {
      BASE_URL: 'https://aus-samhita-backend.samhita.org/v1',
      END_POINT: {
        GET_ADDRESS_BY_PINCODE: '/location/',
      }
    },
    AZURE_SERVICE: {
      BASE_URL: 'https://api.cognitive.microsofttranslator.com',
      END_POINT: {
        LANGUAGES: '/languages',
        TRANSLATE: '/translate'
      },
      SECRET_KEY: '3YfSZk7gwmQ5ezIv3MUSPNmufgMSC7Wy',
      REGION: 'eastus'
    }
  },
  userRoleId: {
    ADMIN: '473dc1e2-4c48-4f26-b1b5-70f727f3185b',
    END_USER: '45a68245-6b27-45c1-9af0-af07a49a7305'
  },
  URLS: {
    SAMHITA_APP: 'https://app.samhita.org'
  },
  SECRET_KEY: "3YfSZk7gwmQ5ezIv3MUSPNmufgMSC7Wy",
  SECRET_IV: "1020304050607080"
}
