import { Component, Input, OnInit } from '@angular/core';
import { LoadingType } from '../../types/types';
import { LoadingService } from '../../services/other/loading.service';

@Component({
  selector: 'app-overlay-loading',
  templateUrl: './overlay-loading.component.html',
  styleUrls: ['./overlay-loading.component.scss']
})
export class OverlayLoadingComponent implements OnInit {
  loadingType: LoadingType = LoadingType.LINES;
  loadingMessage: string = 'Loading...';

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loadingContent.subscribe((data: any) => {
      this.loadingMessage = data.message;
      this.loadingType = data.spinner;
    })
  }

  ngOnDestroy(): void {
    if(this.loadingService.loadingContent)this.loadingService.loadingContent.unsubscribe();
  }

}
