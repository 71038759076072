import { Injectable } from '@angular/core';
import { ToastService } from '../other/toaster.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private toastService: ToastService) { }

  async createSession(response: { access_token: string }) {
    const { access_token } = response;
    if (!access_token) {
      this.toastService.presentToast('Failed to get the data!', 'danger');
      return;
    }
    this.getInSideApp({ access: access_token });
  }

  getInSideApp(tokens: { access: string }) {
    this.router.navigate([`auth/${tokens.access}`]);
  }
}
