import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { OTP_LOGIN_PAYLOAD, SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { SharedService } from 'src/app/shared/services/other/shared.service';
import { ToastService } from 'src/app/shared/services/other/toaster.service';
import { LanguageService } from 'src/app/shared/services/other/language.service';
import { SamhitaOndcWoCService } from 'src/app/shared/services/api/samhita-ondc-wo-c.service';
import { AuthService } from 'src/app/shared/services/api/auth.service';

@Component({
  selector: 'app-mobile-otp',
  templateUrl: './mobile-otp.component.html',
  styleUrls: ['./mobile-otp.component.scss']
})
export class MobileOtpComponent implements OnInit {

  selectedLanguage = this.languageService.selectedLanguage;
  TEXT = this.languageService.TEXT;

  otpForm = this.formBuilder.group({
    phone: ['', [Validators.required,]],
    code: ['', [Validators.minLength(6), Validators.maxLength(6)]],
  });

  constructor(
    private languageService: LanguageService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private authService: AuthService,
    private samhitaOndcWoCService: SamhitaOndcWoCService
  ) { }

  ngOnInit(): void {
    this.clearSession();
  }

  clearSession() {
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
  }

  countdown = 30;
  otpSent: boolean = false;
  private countdownSubscription!: Subscription;
  canResendOtp: boolean = true;

  startCountdown() {
    this.countdown = 30;
    this.canResendOtp = false;

    this.countdownSubscription = interval(1000).subscribe(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        this.canResendOtp = true;
        this.countdownSubscription.unsubscribe();
      }
    });
  }

  onOtpSubmit() {
    if (!this.otpSent) {
      this.sendVerifyOtp('send');
    } else {
      this.sendVerifyOtp('verify');
    }
  }
  sendVerifyOtp(type: 'send' | 'verify') {
    const userName = this.otpForm.get('phone')?.value;

    if (!userName) {
      this.toastService.presentToast('Phone number / Email is required', 'warning');
      return;
    }

    const isEmail = userName.includes('@');
    const isPhoneNumber = userName.length === 10 && !userName.includes('@');

    if (!isEmail && !isPhoneNumber) {
      this.toastService.presentToast('Invalid phone number / Email format', 'warning');
      return;
    }

    const payload: OTP_LOGIN_PAYLOAD = {
      phone: isPhoneNumber ? userName : null,
      email: isEmail ? userName : null,
      code: this.otpForm.get('code')?.value || null
    };

    isEmail ? delete payload.phone : delete payload.email;


    if (type === 'send') {
      this.sendOTP(payload);
    } else if (type === 'verify') {
      this.verifyOTP(payload);
    }



  }

  sendOTP(payload: OTP_LOGIN_PAYLOAD) {
    delete payload.code;
    this.samhitaOndcWoCService.requestOTP(payload).subscribe(
      (response: any) => {
        this.handleOtpResponse(response, 'send');
      }
    );
  }

  verifyOTP(payload: OTP_LOGIN_PAYLOAD) {
    if (!payload.code || payload.code.length !== 6) {
      this.toastService.presentToast('Please enter OTP', 'warning');
      return;
    }
    this.samhitaOndcWoCService.OTPLogin(payload).subscribe(
      (response: any) => {
        this.handleOtpResponse(response, 'verify');
      }
    );
  }

  private handleOtpResponse(response: any, type: 'send' | 'verify') {
    if (type === 'send') {
      this.otpSent = true;
      this.startCountdown();
    } else if (type === 'verify') {
      this.authService.createSession(response)
    }
  }

  onPhoneInput(event: any) {
    this.otpForm.get('code')?.reset()
    if (event.target.value.length !== 10 && !event.target.value.includes('@')) {
      this.countdown = 30;
      this.canResendOtp = false;
      this.countdownSubscription?.unsubscribe();
      this.otpSent = false
    }
  }

}
