import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { SharedService } from './shared/services/other/shared.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppConstants } from './shared/constants/constants';
import { BackButtonEvent, SESSION_STORAGE_KEYS } from './shared/types/types';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  showHeader: boolean = true;

  private readonly hiddenRoutes: string[] = AppConstants.HIDE_HEADER_ROUTES;
  showOpenAppButton: boolean = false;

  constructor(
    private platform: Platform,
    private sharedService: SharedService,
    private router: Router,
    private alertCtrl: AlertController
  ) {
    this.platform.ready().then(() => {
      this.logDetectedPlatforms();
      this.registerBackButtonEvent();
      // this.executePlatformSpecificCode();
    });
  }

  private logDetectedPlatforms() {
    const platforms = this.platform.platforms();
    console.log('Detected platforms:', platforms);
  }

  private executePlatformSpecificCode() {
    if (window.location.href.startsWith('http') || this.platform.is('pwa') || this.platform.is('desktop')) {
      console.log('Web or PWA specific code');
    } else if (this.platform.is('android')) {
      console.log('Android specific code');
    } else if (this.platform.is('ios')) {
      console.log('iOS specific code');
    } else if (this.platform.is('hybrid')) {
      console.log('Hybrid (native app) specific code');
    }
  }

  private registerBackButtonEvent() {
    document.addEventListener('ionBackButton', (ev: Event) => this.handleBackButtonEvent(ev));
  }

  private async handleBackButtonEvent(ev: Event) {
    const backButtonEvent = ev as CustomEvent<BackButtonEvent>;
    backButtonEvent.detail.register(-1, async () => {
      await this.showExitAppAlertIfNeeded();
    });
  }

  private async showExitAppAlertIfNeeded() {
    const currentRoute = this.router.url;
    if (this.isExitRoute(currentRoute)) {
      App.exitApp();
      return;
    }
    if (currentRoute === '/borrowers') {
      await this.presentExitAppAlert();
    }
  }

  private isExitRoute(route: string): boolean {
    return route === '/login' || route === '/signup';
  }

  private async presentExitAppAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Exit App',
      message: 'Do you really want to close the app?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.router.navigateByUrl('/borrowers');
          },
        },
        {
          text: 'Close',
          handler: () => {
            App.exitApp();
          },
        },
      ],
    });
    await alert.present();
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.updateHeaderVisibility());
  }

  private updateHeaderVisibility() {
    const currentRoute = this.router.url;
    this.showHeader = !this.hiddenRoutes.some(route => currentRoute.includes(route));
  }
  openInApp() {
    const sessionToken = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)
    const appUrlScheme = `https://auth/${sessionToken.access}?refresh=${sessionToken.refresh}`;
    window.location.href = appUrlScheme; 
  }
}
