import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { LoginComponent } from './core/components/login/login.component';
import { SignupComponent } from './core/components/signup/signup.component';
import { SessionGuard } from './core/guards/session.guard';
import { SetPasswordComponent } from './core/components/set-password/set-password.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule)
  },
  {
    path : 'login',
    component : LoginComponent,
    canActivate : [SessionGuard]
  },
  {
    path : 'auth/:token',
    component : AuthComponent
  },
  {
    path : 'not-found',
    component : NotFoundComponent
  },
  {
    path : 'signup',
    component : SignupComponent
  },
  {
    path : 'set-password',
    component : SetPasswordComponent
  },
  {
    path : '**',
    redirectTo: 'not-found',
    pathMatch : 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
