import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../../endpoints/api-endpoints';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageKeys, SESSION_STORAGE_KEYS } from '../../types/types';
import { SharedService } from './shared.service';
import { TEXT_IN_ALL_LANGUAGES } from 'src/app/language/language';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selectedLanguage = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.CURRENT_LANGUAGE) || 'en';
  constructor(private http: HttpClient, private sharedService: SharedService  ) {}

  // translateAPI(config: any[], targetLanguage: string, activeLanguage: string, keyToTranslate: string): Observable<any> {
  //   const header = this.createHeadersForTranslateAPI();

  //   const textToTranslateArray: any[] = [];
  //   config.forEach(obj => {
  //     if (obj[keyToTranslate]) {
  //       textToTranslateArray.push({ 'Text': obj[keyToTranslate] });
  //     }
  //   });

  //   if (textToTranslateArray.length === 0) {
  //     return new BehaviorSubject(false);
  //   }

  //   const params = this.createParamsForTranslate(activeLanguage, targetLanguage);

  //   return this.http.post(`${ENDPOINTS.AZURE_SERVICE.TRANSLATE}`, textToTranslateArray, { headers: header, params: params });
  // }

  // getAllTranslateSupportedLanguages() {
  //   return this.http.get(`${ENDPOINTS.AZURE_SERVICE.LANGUAGES}?api-version=3.0`);
  // }
  // private createHeadersForTranslateAPI(): HttpHeaders {
  //   return new HttpHeaders({
  //     'Ocp-Apim-Subscription-Key': ENDPOINTS.AZURE_SERVICE.SECRET_KEY,
  //     'Ocp-Apim-Subscription-Region': ENDPOINTS.AZURE_SERVICE.REGION,
  //     'Content-Type': 'application/json'
  //   });
  // }

  // private createParamsForTranslate(activeLanguage: string, targetLanguage: string): HttpParams {
  //   return new HttpParams()
  //     .set('api-version' , '3.0')
  //     .set('from', activeLanguage)
  //     .set('to', targetLanguage);
  // }



  TEXT(key: LanguageKeys): string {
    return TEXT_IN_ALL_LANGUAGES[key][this.selectedLanguage as 'hi' | 'en'];
  }

  updateLanguage(language: string) {
    this.sharedService.setDataToSessionStorage({key: SESSION_STORAGE_KEYS.CURRENT_LANGUAGE, data: language});
  }


}
